@import 'src/styles/colors';

$buttonHeight: 3.6rem;
$buttonRadius: 1.8rem;
$animationSpeed: 200ms;

.component {
  display: inline-block;
  height: $buttonHeight;
  margin: 0;
  padding: 0 1.5rem;
  outline: 0;
  border: 0;
  border-radius: $buttonRadius;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  letter-spacing: 0.05rem;
  line-height: 0.8;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  &[disabled] {
    opacity: .3;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.contained {
  color: $white;
  background-color: $red;
  transition: background-color $animationSpeed;

  &:hover {
    background-color: lighten($red, 5%);
  }
}

.outlined {
  color: $red;
  border: 0.1rem solid transparentize($red, 0.5);
  transition: border-color $animationSpeed;

  &:hover {
    border-color: $red;
  }
}

.clear {
  color: $red;
  transition: background-color $animationSpeed;

  &:hover {
    background-color: $offWhite;
  }
}
