.component {
  display: inline-flex;
  position: relative;
  align-items: center;
  user-select: none;
  cursor: pointer;

  input[type="checkbox"] {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
  }

  i {
    font-size: 1.8rem;
    padding: 0.6rem;
  }
}

.disabled {
  opacity: 0.5;
  cursor: default;
}
