@import 'src/styles/colors';

.component {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1.5rem;

  > select {
    width: 15rem;
  }
}

.logo {
  display: block;
  width: 12rem;
  margin-right: 3rem;
}

.actions {
  position: absolute;
  right: 1.5rem;
}

.import {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    display: inline-block;
    height: 3rem;
    padding: 0 1.5rem;
    font-size: 1.5rem;
    line-height: 1.8;
    color: $red;

    &:hover {
      cursor: pointer;
      filter: brightness(1.1);
    }
  }
}
