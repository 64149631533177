@import '../../../styles/colors';

.component {
  appearance: none;
  width: 100%;
  height: 3.0rem;
  padding: 0 3.0rem 0 1.0rem;
  border: .1rem solid $gray;
  border-radius: 1.5rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
	background: transparent url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="%23000000" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>') center right no-repeat;

  &:focus {
    outline: 0;
		border-color: $blue;
  }
}
