// Grayscale colors
$white: #ffffff;
$offWhite: #f5f5f5;
$gray: #e0e0e0;
$offBlack: #616161;
$black: #111111;

// Theme colors
$red: #e20150;
$green: #97bd0d;
$blue: #22b8ce;
$orange: #f59d11;

$colors: (
  red: $red,
  green: $green,
  blue: $blue,
  orange: $orange
);
