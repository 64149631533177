@import '~minireset.css/minireset.css';
@import 'styles/colors';

html {
  font-size: 62.5%;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1.6;
  background: $white;
}
