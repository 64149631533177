@import 'src/styles/colors';

.component {
  display: flex;
  padding: 0 1.5rem;
}

.item {
  position: relative;
  display: block;
  min-width: 18rem;
  margin: 0rem;
  padding: 0.6rem 1.2rem;
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  color: $white;
  background-color: lighten($offBlack, 0.6);
  transition: background-color 200ms;

  svg {
    width: 12px;
    height: 12px;
    position: absolute;
    transition: fill 200ms;
    fill: $offBlack;

    &:first-child {
      bottom: 0;
      left: 0;
      transform: translateX(-100%) scaleX(-1);
    }

    &:last-child {
      bottom: 0;
      right: 0;
      transform: translateX(100%);
    }
  }

  &:not(.active):hover {
    background-color: lighten($offBlack, 5%);
    svg { fill: lighten($offBlack, 5%); }
  }

  @each $color, $value in $colors {
    &-#{$color} {
      background-color: $value;
      svg { fill: $value; }

      &:not(.active):hover {
        background-color: lighten($value, 5%);
        svg { fill: lighten($value, 5%); }
      }
    }
  }

  &.active { z-index: 1; }
}
